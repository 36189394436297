/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_access_key_id": 'AKIAV3N7ULSX3TCI3THD',
    "aws_secret_access_key": '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'
};


export default awsmobile;


